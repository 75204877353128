<template>
  <div class="flex-column" style="height: 100%">
    <div class="flex-row justify-between align-items-start margin-bottom-5" style="flex: 0 0 auto;">
      <div>
        <div class="">文件预览</div>
        <!-- <div class="color-sub font-size-12 margin-bottom-10 margin-top-5">总行数：{{ datalist.length }}&emsp;总列数：{{ columns.length }}</div> -->
      </div>
      <div>
        <el-button  size="mini" @click="initupload" >清空预览</el-button>
        <el-button  size="mini" @click="importData" >{{ uploadBtnText }}</el-button>
      </div>
    </div>
    <div v-loading="loading" style="flex: 1 1 auto;">
      <el-upload  ref="upload-drag" v-if="datalist.length==0"
                 style="height: 100%"
                 action=""
                 drag
                 multiple :show-file-list="false"
                 accept=".xls,.XLS,.xlsx,.XLSX"
                 :http-request="httpRequest">
        <div style="margin-top:20%;text-align: center">请将要上传的.xlsx文件拖入此处，上传文件。</div>
      </el-upload>
      <div v-else>
        <basic-table :max-height="630"   :data="datalist" :columns="columns">
          <template v-slot:verifyData="label">
            <el-table-column
                :prop="label.prop"
                :label="label.label">
              <template v-slot="scope">
                <div v-if="label.label=='日期'" >
                  <div type="danger"  v-if="verifyDateTime(scope.row[label.prop])">{{dateTimeformatter(scope.row[label.prop])}}</div>
                  <el-tag class="errdata" type="danger" v-else>{{dateTimeformatter(scope.row[label.prop])}}</el-tag>
                </div>
                <div v-else>
                  <div v-if="verifyIsNum(scope.row[label.prop])">{{scope.row[label.prop]}}</div>
                  <el-tag  v-else class="errdata" type="danger" >{{scope.row[label.prop]}}</el-tag>
                </div>
              </template>
            </el-table-column>
          </template>
        </basic-table>
      </div>
    </div>
  </div>
</template>
<script>
import BasicTable from "./basicTable";
import XLSX from 'xlsx';
//import qs from 'qs';
export default {
  props:{
    isUpdata:{
      type:Boolean,
      default: false,
    },
    uploadBtnText:{
      type:String,
      default:"导入数据",
    },
    fileId:{
       default:"",
    },
  },
  name: "uploadForExcel",
  components: {BasicTable},
  data() {//数据
    return {
      loading:false,
      isDelDs:true,
     // fileId:'',
      url:{
        updateNotice:"/userdata/updateNotice",
        importDataUrl:'/userdata/importExcel',
        importUpdateUrl:'/userdata/updateExcelData',
      },
      dateMapObj:new Map(),
      datalist:[],
      columns:[],
      verifyShowMsg:true,
      modelList:[],
      deleteHead:[],
      isMunflag:true,
      file:{
        data:null,
        verify:false,
      }
    };
  },
  created() {//实例初始化之前的方法

  },
  mounted() {//实例挂载之前的方法

  },
  methods: {//vue实例的方法
    verifyDateTime(dateTime){
      console.log(dateTime);
      if(dateTime instanceof Date){
        
        if(this.dateMapObj.get(dateTime.getTime())>1){
          console.log(dateTime);
          this.isMunflag=false;
          this.file.verify=false;
          console.log("file.verify=false");
          return false;
        }
        return true;
      }else {
        this.file.verify=false;
        this.isMunflag=false;
        return false;
      }
    },
    verifyIsNum(data){
     let num=0;
      if(data.toString().indexOf(".")>-1){
        num=data.toString().split(".")[1].length;
      }
       let munflag=false;
      if(isNaN(data) || num > 7) {
        this.isMunflag=munflag;
        munflag=false;
      }else{
        munflag=true
      }
      return munflag;
    },
    verifyIsShowMsg(){
      if(this.isMunflag==false){//格式异常
        this.showVerifyMsg();
      }
      // if(this.modelList.length!=0 && this.deleteHead.length!=0){//格式异常+更新数据不包含原数据项+原数据项被模型使用
      //   let text=`您上传的文件中发现：`;
      //   if(this.isMunflag==false){
      //     text+=`<br/>1.数据格式异常，格式异常的数据列将无法导入。<br/>2.`;
      //   }
      //   let deltext="";
      //   this.deleteHead.forEach(v=>{deltext+=v+",";});
      //   text+=`更新数据中不包含原有数据，更新数据中不包含的原有数据${deltext}会被清除，需要您调整使用该数据的模型。`;
      //   let modeltext="";
      //   this.modelList.forEach(v=>{modeltext+=v+`<br/>`;});
      //   text+=`<br/><br/>被清除数据影响的模型：<br/>${modeltext}`;
      //   this.showVerifyMsg(text);
      // }
      // if(this.modelList.length==0 && this.deleteHead.length!=0){//格式异常+更新数据不包含原数据项
      //   let text=`您上传的文件中发现：`;
      //   if(this.isMunflag==false){
      //     text+=`<br/>1.数据格式异常，格式异常的数据列将无法导入。<br/>2.`;
      //   }
      //   let deltext="";
      //   this.deleteHead.forEach(v=>{deltext+=v+",";});
      //   text+=`更新数据中不包含原有数据，更新数据中不包含的原有数据${deltext}会被清除，需要您调整使用该数据的模型。`;
      //   this.showVerifyMsg(text);
      // }
    },
    showVerifyMsg(text='您上传的文件中发现：'+`<br/>`+'数据格式异常，格式异常的数据列将无法导入。'){
      if(this.verifyShowMsg){
        this.errorShowMsg(text);
      }
      this.verifyShowMsg=false;
    },
    async importData(){
      var that=this;
     
      if(this.file.data==null){
        this.errorShowMsg('请先上传文件');
      }
      if(this.file.verify){
        // console.log(this.file);
        // console.log(this.file.data);//数据导入异常，请检查数据格式，及网络是否异常。
        this.loading=true;
        let form = new FormData()
        let ispass=true;
        form.append("file", this.file.data);
        let urlPath=this.url.importDataUrl;
        if(this.isUpdata){//更新数据
          urlPath=this.url.importUpdateUrl;
          form.append("fileId", this.fileId);
          ispass=await that.getUpdateNotice();  //验证数据是否继续更新
          console.log(ispass);
        }
        if(ispass){
           this.updatefrom(urlPath,form);
        }else{
          this.initupload();
          this.loading=false;
        }
     
       // this.updatefrom(urlPath,form);
        
       
      }else{
        this.errorShowMsg('数据格式错误，请重新上传文件');
      }
    },
    updatefrom(urlPath,form){
           this.$instance.post(urlPath,form,{headers:{'content-type': 'multipart/form-data'}}).then(res=>{
                this.loading=false;
                if(res.data.code==0){
                    this.okUpdataMsg(res.data);
                    this.initupload();
                }else {
                  this.errorShowMsg(res.data.msg);
                }
              });
    },
    isExcel(file) {
      return /\.(xlsx|xls)$/.test(file.name.toLowerCase());
    },
    add0(m){return m<10?'0'+m:m },
    dateTimeformatter(data){
      if(data instanceof Date && !isNaN(data.getTime())){
        let dt = new Date(data)
        return dt.getFullYear() + '/' + this.add0((dt.getMonth() + 1)) + '/' + this.add0(dt.getDate());
      }else {
        return data;
      }
    },
    numToDate(num){
      let time = new Date((num-25567) * 24 * 3600000 - 5 * 60 * 1000 - 43 * 1000  - 24 * 3600000 - 8 * 3600000)
     return time;
    },
    initupload(){
      this.verifyShowMsg=true;
      this.isMunflag=true;
      this.datalist=[];
      this.columns=[];
      this.file={data:null, verify:false};
      this.dateMapObj=new Map();
    },
    httpRequest (e) {
      this.isDelDs=false;
      this.loading=true;
      this.initupload();
      let that=this;
      let file = e.file // 拿到文件信息
      // console.log(file.size);
      let m5=5*1024*1024;
      const isLt5M = file.size > m5;
      if(isLt5M){
        this.errorShowMsg("上传体积不能超过 5M!")//
        return false
      }
      if (!file) {
        this.errorShowMsg("请上传文件")
        return false
      } else if (!this.isExcel(file)) {
        // 这里格式根据自己需求定义
        this.errorShowMsg("格式错误，请上传xls或者xlsx格式")//格式错误，请上传xls或者xlsx格式
        return false
      }
      const fileReader = new FileReader()
      fileReader.onload = async (ev) => {
        const data = ev.target.result
        const workbook = XLSX.read(data, {type: 'binary'});
        // console.log("workbook-->",workbook);
        if (workbook.SheetNames.length > 1) {
          this.errorShowMsg("excel中只能有一个sheet数据页")//excel中只能有一个sheet数据页
          return false;
        }
        // 获取第一张表
        const exlname = workbook.SheetNames[0]
        // console.log("workbook.Sheets[exlname]-->",workbook.Sheets[exlname]);
        // that.getJsonArr(workbook.Sheets[exlname]);
        const exl = XLSX.utils.sheet_to_json(workbook.Sheets[exlname], {header: 'A',defval:'',raw:false}) // 生成json表格内容
        const exlDate = XLSX.utils.sheet_to_json(workbook.Sheets[exlname], {header: 'A',defval:''}) // 生成json表格内容
        if (exl.length == 0) {
          this.errorShowMsg("请为excel编辑数据后，再上传！")//excel中第一列只能是日期格式的数据
          return false;
        }
        let firstRow = exl[0];
        for (let key in firstRow) {
          let column={
            prop:key,
            label:firstRow[key],
          };
          if (that.validationStart(key, "_")) {
            this.errorShowMsg("excel中表头字段，不能以下划线开头！")
            return false;
          }
          column.scopedSlots = {customRender: 'verifyData'};
          that.columns.push(column);
        }
        if (that.columns.length < 2) {
          this.errorShowMsg("excel中必须至少包含两列！")
          return false;
        }
        if (that.columns.length > 100) {
          this.errorShowMsg("excel中总列数不得超过100列！")
          return false;
        }
        exl.shift();
        exlDate.shift();
        // try {
          for (let i=0;i<exl.length;i++){
            let v=exl[i];
            let date = that.numToDate(exlDate[i]['A']);
            if (date instanceof Date && !isNaN(date.getTime())) {
              date=new Date(date.getTime()+(43*1000));
              v['A']=date;
              let timeKey = date.getTime();
              let num = that.dateMapObj.get(timeKey);
              if (num) {
                that.dateMapObj.set(timeKey, ++num);
              } else {
                that.dateMapObj.set(timeKey, 1);
              }
            }else{
              // throw new Error("excel中第一列只能是日期格式的数据");
            }
          }
        // } catch (e) {
        //   this.errorShowMsg("excel中第一列只能是日期格式的数据")//excel中第一列只能是日期格式的数据
        //   return false;
        // }
        // if(this.isUpdata){
        
        // }
        that.file.data = file;
        that.file.verify = true;
       
       // console.log("file.verify=true");
        that.datalist = exl;
        that.loading = false;
        this.$nextTick(()=>{
          this.verifyIsShowMsg();
        });
      }
      fileReader.readAsBinaryString(file)
    },
    getJsonArr(){
      // let rowlist=[];
      // for (let i in sheets) {
      //   if(i!='!ref' && i!='!margins'){
      //     let rowobj={
      //
      //     }
      //     console.log('属性名：', i.match(/[a-zA-Z]/g).join(""));
      //     console.log('属性值：', sheets[i]);
      //   }
      // }
    },
    async getUpdateNotice(){
          var that=this;
          let headList = [];
          that.columns.forEach(res => {
            headList.push(res.label);
          });
         
          let res= await this.$instance.get(this.url.updateNotice,{params:{fileId:this.fileId,headList:headList.join(',')}});
        
          if(res.data.info){
           return this.$confirm('您要更新的表格和之前不一样，是否要继续更新?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
               return true;
            }).catch(() => {
              return false;
              // this.initupload();    
              // return false;   
            });
          }else{
            return true;
          }
     
    },
    validationStart (str, appoint) {
      var char= str.substr(0,appoint.length);//从0开始，往后截取特定字符长度
      if(char== appoint){ //两者相同，则代表验证通过
        return true;
      }
      return false;
    },
    okUpdataMsg(obj){
      this.loading=false;
      this.$alert(obj.info.successmsg, '数据更新成功', {
        confirmButtonText: '知道了',
        dangerouslyUseHTMLString: true,
        customClass:'custom-msgbox',
      });
    },
    okShowMsg(text){
      this.loading=false;
      this.$alert(text, '数据导入成功', {
        confirmButtonText: '知道了',
        dangerouslyUseHTMLString: true,
        customClass:'custom-msgbox',
      });
    },
    errorShowMsg(text='您上传的文件中发现：'+`<br/>`+'数据格式异常，格式异常的数据列将无法导入。'){
      this.loading=false;
      this.$alert(text, '数据格式异常提示', {
        confirmButtonText: '知道了',
        dangerouslyUseHTMLString: true,
        customClass:'custom-msgbox',
        type: 'warning',
      });
      // this.$message.error(text);
      // this.$emit("ok",this.file);
    },
    isEmptyObject(obj) {
      if (!obj || typeof obj !== 'object' || Array.isArray(obj))
        return false
      return !Object.keys(obj).length
    }
  },
}
</script>

<style scoped>
.errdata{
  border-color: rgba(249, 79, 82, 1);
  background-color: rgba(254, 228, 230, 1);
}
</style>
