<template>
  <div class="">
    <el-table
        :data="data"
        v-loading="loading"
        :size="size"
        :max-height="maxHeight"
        style="width: 100%">
      <template v-for="(item,i) in columns" >
        <slot v-if="item.scopedSlots"   :name="item.scopedSlots.customRender"  :label="item.label" :prop="item.prop" ></slot>
        <el-table-column  v-else :key="i"
                          :prop="item.prop"
                          :label="item.label"
                          :width="item.width"
                          :show-overflow-tooltip='item["show-overflow-tooltip"]'
                          :formatter="item.formatter">
          <el-table-column v-for="(val,i) in item.children" :key="i"
                           :prop="val.prop"
                           :label="val.label">
          </el-table-column>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {//设置传参
    data:[],
    columns:[],
    pagination:{},
    maxHeight: {
      type:[String,Number],
    },
    size:{
      type:String,
      default:"medium",
    },
    loading: {
      type:Boolean,
      default:false,
    },
  },
  name: "basicTable",
  data() {//数据
    return {};
  },
  computed: {//计算属性(缓存计算数据)

  },
  watch: {//监听器(监听数据变化)

  },
  created() {//实例初始化之前的方法

  },
  mounted() {//实例挂载之前的方法

  },
  methods: {//vue实例的方法

  },

}
</script>

<style scoped>

</style>
